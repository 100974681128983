<template>
  <section
    id="our-team"
    class="n-section-primary"
    :style="`background-color: var(--c-${vars.backgroundColor});color: ${vars.textColor ? vars.textColor : 'var(--c-text-primary)'}`"
  >
    <MotionGroup :preset="vars.animationText ? vars.animationText : 'fadeVisible'" :duration="900">
      <div v-if="vars.titleHtml" v-html="vars.titleHtml" :class="{ 'tw-mb-8': variants.v3 === variant }"></div>
      <h3 class="tw-mb-4" v-else-if="vars.titleText">{{ vars.titleText }}</h3>
      <div
        v-if="variants.v1 === variant"
        class="scrollbar-hide tw-my-4 tw-flex tw-w-96 tw-snap-x tw-snap-mandatory tw-snap-start tw-items-center tw-justify-center tw-overflow-x-auto md:tw-mb-14 md:tw-w-full md:tw-gap-5 md:tw-overflow-x-hidden"
      >
        <button
          v-for="(branch, index) in branches"
          :key="index"
          class="select-button tw-mx-2 !tw-w-[unset] tw-text-nowrap"
          :class="selectedBranch === index ? 'select-button active' : 'select-button'"
          @click="selectedBranch = branch.id"
        >
          {{ branch.name }}
        </button>
      </div>
      <nuxt-link
        :to="vars.buttonLinkText"
        v-if="variants.v2 === variant"
        :class="`n-${vars.buttonTypeText} tw-my-6`"
        :external="true"
      >
        {{ vars.buttonLabelText }}
      </nuxt-link>
      <!-- Swiper for Mobile -->
      <div class="tw-visible tw-relative tw-max-w-[320px] sm:tw-max-w-full lg:tw-hidden">
        <Swiper
          :modules="[SwiperPagination]"
          :pagination="true"
          :slides-per-view="1"
          :space-between="20"
          effect="fade"
          @swiper="onSwiper"
          :clickable="true"
          :breakpoints="{
            320: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
          }"
          class="tw-w-full tw-max-w-sm md:tw-max-w-2xl"
        >
          <SwiperSlide
            v-for="(item, index) in teamToDisplay"
            :key="index"
            class="!tw-flex !tw-h-auto tw-max-w-none tw-items-center tw-justify-center tw-px-3"
            @click="toggleTeamMemberDetailPopup(item, true)"
          >
            <div
              class="n-team-card tw-flex tw-h-full tw-w-full tw-flex-col tw-gap-6 tw-rounded-lg tw-border-2 tw-p-3 tw-text-start"
              :class="{ '!tw-border-0 !tw-bg-white': variants.v3 === variant }"
            >
              <library-image :src="item.member_photo" :hRatio="1.5" :wRatio="1" :key="item.member_name" />
              <div class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-4 tw-text-center">
                <div class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-1 tw-break-words">
                  <h6 v-if="variants.v1 === variant || variants.v2 === variant">
                    {{ item.member_name }}
                  </h6>
                  <h4 v-if="variants.v3 === variant">{{ item.member_name }}</h4>
                  <span
                    v-if="(variants.v2 === variant || variants.v1 === variant) && item.member_role"
                    class="!tw-font-bold"
                    >{{ item.member_role }}</span
                  >
                  <p v-if="variants.v3 === variant" class="!tw-font-bold">
                    {{ item.member_role }}
                  </p>
                </div>
                <div class="tw-flex tw-w-full tw-flex-col tw-items-center">
                  <div
                    v-if="item.member_phone"
                    class="tw-flex tw-w-full tw-items-center tw-justify-center tw-gap-2 tw-break-words"
                  >
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M13.34 10.2533C12.52 10.2533 11.7267 10.12 10.9867 9.88C10.8707 9.8407 10.7461 9.83487 10.627 9.86317C10.5079 9.89147 10.3992 9.95275 10.3133 10.04L9.26667 11.3533C7.38 10.4533 5.61333 8.75333 4.67333 6.8L5.97333 5.69333C6.15333 5.50667 6.20667 5.24667 6.13333 5.01333C5.88667 4.27333 5.76 3.48 5.76 2.66C5.76 2.3 5.46 2 5.1 2H2.79333C2.43333 2 2 2.16 2 2.66C2 8.85333 7.15333 14 13.34 14C13.8133 14 14 13.58 14 13.2133V10.9133C14 10.5533 13.7 10.2533 13.34 10.2533Z"
                        fill="black"
                      />
                    </svg>
                    <nuxt-link :to="`tel:${item.member_phone}`" class="n-link">
                      <p class="tw-max-w-full">
                        {{ item.member_phone }}
                      </p>
                    </nuxt-link>
                  </div>
                  <div v-if="item.member_email" class="tw-flex tw-items-center tw-justify-center tw-gap-2 tw-text-wrap">
                    <span>
                      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M2.66671 13.3334C2.30004 13.3334 1.98604 13.2027 1.72471 12.9414C1.46338 12.68 1.33293 12.3662 1.33338 12V4.00002C1.33338 3.63335 1.46404 3.31935 1.72538 3.05802C1.98671 2.79669 2.30049 2.66624 2.66671 2.66669H13.3334C13.7 2.66669 14.014 2.79735 14.2754 3.05869C14.5367 3.32002 14.6672 3.6338 14.6667 4.00002V12C14.6667 12.3667 14.536 12.6807 14.2747 12.942C14.0134 13.2034 13.6996 13.3338 13.3334 13.3334H2.66671ZM8.00004 8.66669L13.3334 5.33335V4.00002L8.00004 7.33335L2.66671 4.00002V5.33335L8.00004 8.66669Z"
                          fill="black"
                        />
                      </svg>
                    </span>
                    <nuxt-link :to="`mailto:${item.member_email}`" class="n-link">
                      <p class="tw-break-all tw-lowercase">
                        {{ item.member_email }}
                      </p>
                    </nuxt-link>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
        <div class="lg:!tw-hidden">
          <LibrarySliderPagination
            :swiper="swiper"
            variant="v3"
            :can-paginate-back="canPaginateBack"
            :can-paginate-next="canPaginateNext"
          />
        </div>
      </div>
      <div class="tw-hidden tw-w-full tw-justify-center tw-gap-4 lg:tw-flex lg:tw-flex-wrap lg:tw-gap-6 xl:tw-gap-6">
        <div
          v-for="(item, index) in teamToDisplay"
          :key="index"
          class="n-team-card w-flex tw-flex tw-min-h-[520px] tw-flex-[0_0_48%] tw-flex-col tw-justify-start tw-gap-6 tw-rounded-lg tw-border-2 tw-bg-white tw-p-3 tw-text-start xl:tw-h-auto xl:tw-w-[295px] xl:tw-flex-initial 2xl:tw-min-h-[680px] 2xl:tw-w-[350px] 2xl:tw-min-w-[350px]"
          :class="{ '!tw-border-0 !tw-bg-white': variants.v3 === variant }"
          @click="toggleTeamMemberDetailPopup(item, true)"
        >
          <div class="tw-overflow-hidden tw-rounded">
            <library-image :src="item.member_photo" :hRatio="1.5" :wRatio="1" :key="item.member_name" />
          </div>
          <div class="tw-flex tw-min-h-[140px] tw-flex-col tw-items-center tw-justify-start tw-gap-4 tw-text-center">
            <div class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-1">
              <h6>{{ item.member_name }}</h6>
              <span class="!tw-font-bold">{{ item.member_role }}</span>
            </div>
            <div class="tw-flex tw-w-full tw-flex-col tw-items-center">
              <div
                v-if="item.member_phone"
                class="tw-flex tw-w-full tw-items-center tw-justify-center tw-gap-2 tw-break-words"
              >
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M13.34 10.2533C12.52 10.2533 11.7267 10.12 10.9867 9.88C10.8707 9.8407 10.7461 9.83487 10.627 9.86317C10.5079 9.89147 10.3992 9.95275 10.3133 10.04L9.26667 11.3533C7.38 10.4533 5.61333 8.75333 4.67333 6.8L5.97333 5.69333C6.15333 5.50667 6.20667 5.24667 6.13333 5.01333C5.88667 4.27333 5.76 3.48 5.76 2.66C5.76 2.3 5.46 2 5.1 2H2.79333C2.43333 2 2 2.16 2 2.66C2 8.85333 7.15333 14 13.34 14C13.8133 14 14 13.58 14 13.2133V10.9133C14 10.5533 13.7 10.2533 13.34 10.2533Z"
                    fill="black"
                  />
                </svg>
                <p class="tw-max-w-full">
                  {{ item.member_phone }}
                </p>
              </div>
              <div v-if="item.member_email" class="tw-flex tw-items-center tw-justify-center tw-gap-2 tw-text-wrap">
                <span>
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M2.66671 13.3334C2.30004 13.3334 1.98604 13.2027 1.72471 12.9414C1.46338 12.68 1.33293 12.3662 1.33338 12V4.00002C1.33338 3.63335 1.46404 3.31935 1.72538 3.05802C1.98671 2.79669 2.30049 2.66624 2.66671 2.66669H13.3334C13.7 2.66669 14.014 2.79735 14.2754 3.05869C14.5367 3.32002 14.6672 3.6338 14.6667 4.00002V12C14.6667 12.3667 14.536 12.6807 14.2747 12.942C14.0134 13.2034 13.6996 13.3338 13.3334 13.3334H2.66671ZM8.00004 8.66669L13.3334 5.33335V4.00002L8.00004 7.33335L2.66671 4.00002V5.33335L8.00004 8.66669Z"
                      fill="black"
                    />
                  </svg>
                </span>
                <p class="tw-break-all tw-lowercase">
                  {{ item.member_email }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MotionGroup>

    <MotionGroup :preset="vars.animationText ? vars.animationText : 'popVisible'" :duration="400">
      <popup v-if="selectedMember && popupConfig.isActive" :config="popupConfig" custom-width="90%">
        <template #content>
          <div class="tw-grid tw-h-full tw-w-full tw-grid-cols-12 tw-gap-y-4 tw-py-5 lg:tw-gap-x-6">
            <div class="tw-col-span-12 tw-flex tw-w-full tw-flex-col tw-justify-center lg:tw-col-span-7">
              <h3 v-if="variants.v1 === variant || variants.v2 === variant">
                {{ selectedMember.popup_title }}
              </h3>
              <h2 v-if="variants.v3 === variant">
                {{ selectedMember.popup_title }}
              </h2>
              <h6 v-if="variants.v1 === variant || variants.v2 === variant">
                {{ selectedMember.member_role }}
              </h6>
              <p v-if="variants.v3 === variant" class="!tw-font-bold">
                {{ selectedMember.member_role }}
              </p>
              <div class="tw-py-4">
                <div v-if="selectedMember.member_email" class="tw-flex tw-items-center tw-gap-1 tw-pb-3">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M2.66671 13.3334C2.30004 13.3334 1.98604 13.2027 1.72471 12.9414C1.46338 12.68 1.33293 12.3662 1.33338 12V4.00002C1.33338 3.63335 1.46404 3.31935 1.72538 3.05802C1.98671 2.79669 2.30049 2.66624 2.66671 2.66669H13.3334C13.7 2.66669 14.014 2.79735 14.2754 3.05869C14.5367 3.32002 14.6672 3.6338 14.6667 4.00002V12C14.6667 12.3667 14.536 12.6807 14.2747 12.942C14.0134 13.2034 13.6996 13.3338 13.3334 13.3334H2.66671ZM8.00004 8.66669L13.3334 5.33335V4.00002L8.00004 7.33335L2.66671 4.00002V5.33335L8.00004 8.66669Z"
                      fill="black"
                    />
                  </svg>
                  <nuxt-link :to="`mailto:${selectedMember.member_email}`" class="n-link">
                    <p>{{ selectedMember.member_email }}</p>
                  </nuxt-link>
                </div>
                <div v-if="selectedMember.member_phone" class="tw-flex tw-items-center tw-gap-1">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M13.34 10.2533C12.52 10.2533 11.7267 10.12 10.9867 9.88C10.8707 9.8407 10.7461 9.83487 10.627 9.86317C10.5079 9.89147 10.3992 9.95275 10.3133 10.04L9.26667 11.3533C7.38 10.4533 5.61333 8.75333 4.67333 6.8L5.97333 5.69333C6.15333 5.50667 6.20667 5.24667 6.13333 5.01333C5.88667 4.27333 5.76 3.48 5.76 2.66C5.76 2.3 5.46 2 5.1 2H2.79333C2.43333 2 2 2.16 2 2.66C2 8.85333 7.15333 14 13.34 14C13.8133 14 14 13.58 14 13.2133V10.9133C14 10.5533 13.7 10.2533 13.34 10.2533Z"
                      fill="black"
                    />
                  </svg>
                  <nuxt-link :to="`tel:${selectedMember.member_phone}`" class="n-link">
                    <p>{{ selectedMember.member_phone }}</p>
                  </nuxt-link>
                </div>
              </div>
              <p>{{ selectedMember.popup_description }}</p>
              <nuxt-link
                class="tw-mt-4 tw-max-w-fit"
                :class="`n-${selectedMember.popup_button_type}`"
                :external="true"
                :to="`mailto: ${selectedMember.popup_button_email}`"
              >
                {{ selectedMember.popup_button_label }}
              </nuxt-link>
            </div>
            <div
              class="tw-col-span-12 tw-flex tw-items-center tw-justify-center md:tw-col-span-8 lg:tw-col-span-5"
              :class="{
                'tw-order-first lg:tw-order-none': variants.v3 === variant,
              }"
            >
              <div>
                <div style="position: relative; height: 100%; width: 100%">
                  <NuxtImg
                    v-if="selectedMember.popup_video_cover"
                    :src="selectedMember.popup_video_cover"
                    :alt="selectedMember.member_name"
                    class="tw-w-full tw-rounded"
                  />
                  <div
                    class="tw-absolute tw-top-0 tw-z-10 tw-h-full tw-w-full"
                    @click="isVisible = true"
                    v-if="selectedMember?.popup_video"
                  >
                    <button class="tw-absolute tw-left-1/2 tw-top-1/2 -tw-translate-x-1/2 -tw-translate-y-1/2">
                      <svg width="80" height="80" viewBox="0 0 162 162" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="81" cy="81" r="81" fill="white" />
                        <polygon points="65,45 115,81 65,117" fill="black" />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </popup>
    </MotionGroup>

    <library-popups-video-popup
      v-if="selectedMember?.popup_video"
      :videoUrl="selectedMember?.popup_video"
      v-model:is-visible="isVisible"
    />
  </section>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';
import Popup from '~/components/common/popup/Popup.vue';

export default defineNuxtComponent({
  name: 'OurTeam',

  mixins: [ComponentMixin],

  components: { Popup },

  data() {
    return {
      swiper: null,
      canPaginateBack: true,
      canPaginateNext: true,
      selectedMember: null,
      selectedBranch: 0,
      variants: {
        v1: 'v1',
        v2: 'v2',
        v3: 'v3',
      },
      popupConfig: {
        isActive: false,
      },
      isVisible: false,
    };
  },

  mounted() {
    if (this.variant === this.variants.v3) {
      this.onSlideChange();
    }
  },

  computed: {
    variant() {
      return useVariant(this.component);
    },

    branches() {
      return this.groupedVariables.branches;
    },

    teamToDisplay() {
      if (this.variants.v1 === this.variant) {
        return this.groupedVariables.teams.filter((branch) => branch.branchid === this.selectedBranch);
      } else if (this.variants.v2 === this.variant || this.variants.v3 === this.variant) {
        return this.groupedVariables.teams;
      } else {
        return [];
      }
    },
  },

  methods: {
    toggleTeamMemberDetailPopup(member = null, value = false) {
      if (member.popup_title) {
        this.selectedMember = member;
        this.popupConfig.isActive = value;
      }
    },

    onSwiper(swiper) {
      this.swiper = swiper;
    },

    onSlideChange() {
      if (!this.swiper) return;
      const isAtStart = this.swiper.isBeginning;
      const isAtEnd = this.swiper.isEnd;
      if (isAtStart) this.canPaginateBack = false;
      else this.canPaginateBack = true;
      if (isAtEnd) this.canPaginateNext = false;
      else this.canPaginateNext = true;
    },
  },
});
</script>
