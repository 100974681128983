<template>
  <div :style="cssVariablesBinding">
    <Theme4BaseTheHeader />

    <div v-if="shouldShowComponent" id="msgboxxWidget" />

    <main class="n-main-container">
      <slot />
    </main>

    <Theme4BaseTheFooter />
  </div>
</template>

<script lang="ts">
import { mapState } from 'pinia';
import IndexGlobal from '~/mixins/index.global';
import LayoutsMixin from '~/mixins/layouts.mixin';
import Theme4BaseFooterOne from '~/components/theme4/base/footer/FooterOne.vue';
import { useWebsiteStore } from '~/units/website/store';
import AvailableComponentsForTheme4 from '~/components/theme4/AvailableComponentsForTheme4.vue';

export default defineNuxtComponent({
  name: 'Theme4',

  mixins: [IndexGlobal, LayoutsMixin],

  components: { AvailableComponentsForTheme4, Theme4BaseFooterOne },

  data() {
    return {
      containers: [
        {
          name: 'BLOGS_VIEW',
          classes: 'blog-wrap5',
        },
        {
          name: 'BLOG_DETAIL_VIEW',
          classes: 'blog-wrap6',
        },
      ],
    };
  },

  head(context) {
    if (process.server && context.ssrContext) {
      const websiteStore = useWebsiteStore();
      const isBeercocks = websiteStore.companyKey === 'beercocks';

      const tagScripts = (() => {
        if (process.env.APP_STAGE === 'production') {
          return [
            ...websiteStore.googleTagsScripts,
            {
              key: 'company-analytics',
              type: 'text/javascript',
              src: `/theme4/js/${websiteStore.companyKey}.js`,
              async: false,
            },
          ];
        }
        return [];
      })();
      const scripts = [
        ...tagScripts,
        ...(isBeercocks
          ? [
              {
                src: 'https://cdn.msgboxx.io/static/js/bundle.min.js',
                async: true,
                onload: "window.msgboxxClient='BEERCOCKS';",
                body: true,
              },
            ]
          : []),
      ];

      const links = [
        {
          href: `/theme4/css/${websiteStore.companyKey}/${websiteStore.companyKey}.css`,
          rel: 'stylesheet',
          hid: `css/${websiteStore.companyKey}.css`,
          type: 'text/css',
        },
        ...(isBeercocks
          ? [
              {
                href: `https://cdn.msgboxx.io/static/css/bundle.min.css`,
                rel: 'stylesheet',
                type: 'text/css',
              },
            ]
          : []),
      ];

      const meta = [];
      if (isBeercocks) {
        meta.push({
          name: 'google-site-verification',
          content: 'a92UwyVgSuaCIpTfeqBMziIp49w0a5gXBp9tGrYzJYY',
        });
      }

      return {
        link: links,
        script: scripts,
        meta,
      };
    }
  },

  computed: {
    ...mapState(useWebsiteStore, ['currentPage']),

    isBeercocks() {
      return useWebsiteStore().companyKey === 'beercocks';
    },

    shouldShowComponent() {
      const currentPath = this.$route.path;
      const isInHeadsUpAlerts = currentPath.startsWith('/heads-up-alerts');
      return this.isBeercocks && !isInHeadsUpAlerts;
    },

    cssVariablesBinding() {
      return {
        ...this.cssVariables,
        '--c-primary': this.colors.primary,
        '--c-secondary': this.colors.secondary,
        '--c-border': this.colors.border,
        '--c-input_placeholder': this.colors.input_placeholder,
        '--c-text_white': this.colors.text_white,
        '--c-text_black': this.colors.text_black,
        '--f-primary': this.colors.primary_font,
        '--f-secondary': this.colors.secondary_font,
        '--box-checked-color': this.colors.secondary,
        '--checkbox-checked-color': this.colors.secondary,
        '--white': this.colors.text_white,
        '--default-font': this.colors.primary_font,
        '--default-title-font': this.colors.primary_font,
        '--checkbox-tick-color': 'white',
        '--input--placeholder': this.colors.input_placeholder,
        '--nc-secondary-bg-color': this.colors.primary,
        '--seashell': this.colors.secondary,
        '--dark-brown': '#666666',
        '--default-border-color': this.colors.border,
        '--nc-popup-background-color': 'white',
        '--nc-property-status-bg-color': this.colors.secondary,
        '--nc-property-status-text-color': 'white',
        '--nc-doughnut-chart-value': this.colors.primary,
        '--akya-text-color': this.colors['akya-text-color'],
        '--akya-background-color': this.colors['akya-background-color'] || this.colors['--akya-background-color'],
        '--akya-confirm-button-text-color': this.colors['akya-text-color'],
        '--akya-confirm-button-background-color': this.colors.secondary,
      };
    },
  },
});
</script>

<style scoped></style>
