<template>
  <section
    class="tw-h-[65vh]"
    :style="`color: ${vars.textColor ? `var(--c-${vars.textColor})` : 'var(--c-text-secondary)'}`"
  >
    <div v-if="variants.v1 === variant" class="w-full">
      <div class="tw-absolute tw-inset-0 -tw-top-16">
        <NuxtImg
          :src="isMobileDevice ? vars.mobileBackgroundImage || vars.backgroundImage : vars.backgroundImage"
          alt="Background Image"
          class="tw-h-full tw-w-full tw-object-cover tw-object-top"
        />
      </div>
      <div
        class="tw-absolute tw-inset-0 tw-flex tw-items-end tw-justify-center tw-bg-black tw-bg-opacity-20 lg:tw-justify-start"
      >
        <MotionGroup :preset="vars.animationText ? vars.animationText : 'slideVisibleLeft'" :duration="700">
          <h1 class="tw-mb-28 tw-text-center tw-text-white lg:tw-mb-16 lg:tw-ml-28">
            {{ vars.titleText }}
          </h1>
        </MotionGroup>
      </div>
    </div>

    <div v-if="variants.v2 === variant" class="w-full">
      <div class="tw-absolute tw-inset-0">
        <NuxtImg
          :src="isMobileDevice ? vars.mobileBackgroundImage || vars.backgroundImage : vars.backgroundImage"
          alt="Background Image"
          class="tw-h-full tw-w-full tw-object-cover tw-object-top"
        />
      </div>
      <div
        class="tw-absolute tw-inset-0 tw-opacity-50"
        :style="`background-color: var(--c-${vars.backgroundLayerColor})`"
      ></div>
      <MotionGroup :preset="vars.animationText ? vars.animationText : 'popVisible'" :duration="500">
        <h1 class="tw-relative tw-z-50 tw-text-center">
          {{ vars.titleText }}
        </h1>
      </MotionGroup>
    </div>

    <div v-if="variants.v3 === variant" class="w-full">
      <div class="tw-absolute tw-inset-0">
        <NuxtImg
          :src="isMobileDevice ? vars.mobileBackgroundImage || vars.backgroundImage : vars.backgroundImage"
          alt="Background Image"
          class="tw-h-full tw-w-full tw-object-cover tw-object-top"
        />
      </div>
      <div
        class="tw-absolute tw-inset-0 tw-opacity-50"
        :style="`background-color: var(--c-${vars.backgroundLayerColor})`"
      ></div>
      <MotionGroup :preset="vars.animationText ? vars.animationText : 'popVisible'" :duration="500">
        <div v-html="vars.titleHtml" class="tw-relative tw-z-40 tw-text-white"></div>
      </MotionGroup>
    </div>

    <!-- internal variant -->
    <div v-if="variants.v4 === version">
      <div class="tw-absolute tw-inset-0">
        <NuxtImg
          :src="
            isMobileDevice ? variables.mobileBackgroundImage || variables.backgroundImage : variables.backgroundImage
          "
          alt="Background Image"
          class="tw-h-full tw-w-full tw-object-cover tw-object-top"
        />
      </div>
      <h1 class="tw-relative tw-z-40 tw-pb-10 tw-text-center tw-text-white">Knowledge Hub</h1>
      <div
        class="tw-absolute tw-inset-0 tw-opacity-50"
        :style="`background-color: var(--c-${variables.backgroundLayerColor})`"
      ></div>

      <div class="tw-relative tw-z-40 tw-grid tw-grid-cols-4 tw-gap-5 lg:tw-grid-cols-8">
        <router-link
          v-for="(tab, index) in tabs"
          :key="`knowledge-hub-tab-${index}`"
          :class="activeTab === tab.name ? 'tw-bg-[var(--c-secondary)]' : 'n-secondary'"
          :to="`/knowledge-hub/${tab.name}`"
          :external="true"
          style="text-transform: uppercase; text-align: center; font-weight: bold"
          class="tw-col-span-6 tw-px-4 tw-py-6 lg:tw-col-span-2"
        >
          {{ tab.title }}
        </router-link>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'IntroSection',

  mixins: [ComponentMixin],

  data() {
    return {
      variants: {
        v1: 'v1',
        v2: 'v2',
        v3: 'v3',
        v4: 'v4',
      },
    };
  },

  /* props for variant v4 */
  props: {
    component: {
      type: Object,
      required: false,
    },
    version: {
      type: String,
      required: false,
    },
    variables: {
      type: Object,
      required: false,
    },
    tabs: {
      type: Array,
      required: false,
    },
    activeTab: {
      type: String,
      required: false,
    },
  },

  computed: {
    buttons() {
      return this.groupedVariables.buttons;
    },
  },
});
</script>
